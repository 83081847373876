@import '../../styles/_variables.scss'; // assuming a styles directory under src/
//@import '~nprogress/nprogress'; // importing a css file from the nprogress node module
@import '../../styles/_extends.scss'; // assuming a styles directory under src/
//@import '~nprogress/nprogress'; // importing a css file from the nprogress node module

#consumers {
  user-select: text;
  position: relative;
  background-color: transparent;
  //@include section-margin;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  padding-bottom: 50px;

  @media screen and (min-width: $min-md) {
    padding-bottom: 80px;
  }

  .article {
    position: relative;

    &:first-of-type {
      position: relative;
      background-color: $main-blue;
      color: $text-white;
      width: 70%;
      max-width: 95vw;
      margin: 0 auto;
      top: 60px;
      margin-bottom: 0;
      padding: 30px;
      //z-index: 5;

      .pattern__box {
        z-index: 0;
        margin-bottom: 10%;
      }

      .ellipse__box {
        position: absolute;
        height: 120%;
        bottom: 0%;
        left: 10%;
        z-index: -1;

        .ellipse__pattern {
          text-align: center;
          height: 100%;
          //z-index: 0;
          //transform: translateY(-50%);
        }
      }

      .ellipse__box--inner {
        .ellipse__pattern {
          height: 50%;
          transform: translate(60%, 50%);
        }
      }

      .text__box {
        .section__header {
          text-align: center;
          text-transform: uppercase;
          color: $main-green;
        }

        .section__title,
        .section__text {
          text-align: center;
          color: $text-white;
        }
      }

      @media screen and (min-width: $min-md) {
        @include text-center;
        width: 70%;
        max-width: 80vw;
        top: 200px;

        .pattern__box {
          margin-bottom: 8%;
        }

        .ellipse__box {
          position: absolute;
          height: 200%;
          top: -20%;
          left: 20%;
          z-index: -1;

          .ellipse__pattern {
            text-align: center;
            height: 100%;
            //transform: translate(-50%, -50%);
          }
        }

        .ellipse__box--inner {
          .ellipse__pattern {
            height: 50%;
            transform: translate(50%, 50%);
          }
        }
      }

      @media screen and (min-width: $min-lg) {
        width: 70%;
        max-width: 70%;

        .pattern__box {
          margin-bottom: 8%;
        }
      }
    }

    &:last-of-type {
      display: grid;
      width: 100%;
      z-index: -1;

      @media screen and (min-width: $min-md) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1.2fr 1fr;
      }

      .user__box {
        position: relative;
        padding: 10%;
        @include flex-column;
        align-items: center;

        .box__list {
          list-style: none;
          padding-left: 0;

          .info__text {
            margin: 12px 0;
          }
        }

        .circle {
          min-width: 150px;
          min-height: 150px;
          height: 200px;
          width: 200px;
          margin: 5% auto;
          background-color: $text-white;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;

          .icon--large {
            height: 200px;
            width: 200px;
          }
        }

        .plus__pattern {
          position: absolute;
          height: 20px;
          bottom: -10px;
          z-index: 1;
        }

        &:nth-of-type(1),
        &:nth-of-type(3) {
          background-color: $main-grey;
        }

        &:nth-of-type(2),
        &:nth-of-type(4) {
          background-color: $bg-blueGrey;
        }

        &:nth-of-type(1) {
          padding-top: 80px;
        }

        &:nth-of-type(4) {
          .plus__pattern {
            display: none;
          }
        }

        @media screen and (min-width: $min-md) {

          .circle {
            height: 250px;
            width: 250px;

            .icon--large {
              height: 250px;
              width: 250px;
            }
          }

          &:nth-of-type(1),
          &:nth-of-type(4) {
            background-color: $main-grey;
          }

          &:nth-of-type(2),
          &:nth-of-type(3) {
            background-color: $bg-blueGrey;
          }

          &:nth-of-type(1),
          &:nth-of-type(2) {
            padding-top: 100px;
            justify-content: flex-end;
            align-items: center;
          }

          &:nth-of-type(3),
          &:nth-of-type(4) {
            padding-bottom: 100px;
            justify-content: flex-start;
            align-items: center;
          }

          &:nth-of-type(1){
            .plus__pattern {
              right: -10px;
              bottom: calc(50% - 100px);
            }
          }

          &:nth-of-type(3){
            .plus__pattern {
              top: -10px;
            }
          }

          &:nth-of-type(4){
            .plus__pattern {
              display: block;
              left: -10px;
              top: calc(50% - 50px);
            }
          }
        }
      }
    }
  }
}