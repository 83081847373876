@import '../../styles/_variables.scss'; 
@import '../../styles/_extends.scss';

.gallery__item {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media screen and (min-width: $min-lg) {
        padding: 0 10%;
       // width: 95%;
    }

    .figure__photo {
        display: block;
        width: 100%;

        @media screen and (min-width: $min-lg) {
            width: 80%;
        }
    }
}