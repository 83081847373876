@import '../../styles/_variables.scss'; // assuming a styles directory under src/
//@import '~nprogress/nprogress'; // importing a css file from the nprogress node module

.section__text {
  font-family: $main-font;
  color: $text-black;
  font-weight: 300;
  font-size: 20px;
  line-height: 150%;
}

.info__text {
  font-family: $main-font;
  color: $text-black;
  font-weight: 300;
  font-size: 18px;
  line-height: 100%;
}
