@import "../../styles/_variables.scss"; // assuming a styles directory under src/
//@import '~nprogress/nprogress'; // importing a css file from the nprogress node module
@import "../../styles/_extends.scss";

#team {
  user-select: text;
  position: relative;
  @include flex-column;
  background-color: $main-blue;
  color: $text-white;

  .section__header {
    color: $main-green;
    text-align: center;
  }

  .section__title {
    color: $text-white;
    text-align: center;
  }

  .article {
    height: auto;
    min-height: auto;
    flex: 1;
    align-items: stretch;
    margin: auto;
  }


  .article--boxes {
    @include flex;
    align-items: stretch;
    flex-wrap: wrap;
    padding-left: 0;
    padding-top: 5vh;
    transition: 0.2s;
    justify-content: center;
    align-items: center;
    @include paddingBottom;
    @include paddingLeft;
    @include paddingRight;

    @media screen and (min-width: $min-md) {
      justify-content: space-evenly;
      flex-direction: row;
    }

    .box {
      @include flex-column;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      flex: 1;
      margin: 5px;
      //margin-bottom: 20px;
      background-color: $main-blue;
      height: 190px;
      width: 190px;
      min-height: 170px;
      text-align: left;
      border: 10px solid transparent;
      padding: 20px 10px;

      &:before {
        display: block;
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        background-image: url('/images/icons/Plus-white.svg');
        background-size: contain;
        background-repeat: no-repeat;
        right: 10%;
        top: 0px;
      }

      .box__img {
        display: flex;
        height: 110px;
        min-height: 100px;
        width: 110px;
        margin-bottom: -10px;
        object-fit: cover;
      }

      .text-box {
        width: 100%;
        @include flex-column;
        justify-content: flex-start;
        align-items: flex-start;

        .box__title {
          margin: 0;
          font-size: 22px;
          font-weight: normal;
          line-height: 110%;
        }

        .box__subtitle {
          margin: 0;
          font-size: 16px;
          font-weight: bold;
        }

        .description__text {
          margin: 0;
          font-size: 12px;
          text-align: left;
          line-height: 150%;
        }
      }

      @media screen and (min-width: $min-md) {
        min-width: 35%;
        max-width: 250px;
        margin: 5px;
      }

      @media screen and (min-width: $min-lg) {
        min-width: 35%;
        max-width: 250px;
      }

      @media screen and (min-width: $min-xl) {
        min-width: 20%;
        max-width: 250px;
        min-height: 200px;

        .box__img {
          display: flex;
          height: 130px;
          min-height: 130px;
          width: 130px;
          margin-bottom: -10px;
          object-fit: cover;
        }

        .text-box {

          .box__title {
            font-size: 28px;
          }

          .box__subtitle {
            font-size: 20px;
          }

          .description__text {
            font-size: 14px;
          }
        }
      }
    }
  }
}