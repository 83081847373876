@import '../../styles/_variables.scss';
@import '../../styles/_extends.scss';

.pattern__box {
  display: flex;
  position: relative;
  width: 100%;
  //height: 100%;
  margin-top: 10%;

  @media screen and (min-width: $min-md) {
    margin-top: 5%;
  }

  .points__pattern {
    background-color: transparent;
    z-index: -1;
  }

  .points__pattern--left {
    text-align: left;
  }

  .points__pattern--right {
    text-align: right;
  }

  .points__pattern--center {
    text-align: center;
    margin: 0 auto;
  }

  .points__pattern--horizontal {
    height: 30px;

    @media screen and (min-width: $min-md) {
      height: 40px;
    }

    @media screen and (min-width: $min-lg) {
      height: 60px;
    }
  }

  .points__pattern--vertical {
    height: 30px;

    @media screen and (min-width: $min-md) {
      height: 40px;
    }

    @media screen and (min-width: $min-lg) {
      height: 60px;
    }

    transform: translateX(-33%) rotateZ(90deg);
    -webkit-transform: translateX(-33%) rotateZ(90deg);
    -moz-transform: translateX(-33%) rotateZ(90deg);
    -ms-transform: translateX(-33%) rotateZ(90deg);
    -o-transform: translateX(-33%) rotateZ(90deg);
  }
}