@import '../../styles/_variables.scss'; // assuming a styles directory under src/
//@import '~nprogress/nprogress'; // importing a css file from the nprogress node module

.section__title {
  font-family: $main-font;
  color: $text-black;
  font-weight: 300;
  font-size: 35px;
  line-height: 100%;

  @media screen and (min-width: $min-md) {
    font-size: 60px;
  }
}

.section__header {
  font-family: $main-font;
  color: $text-white;
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;

  @media screen and (min-width: $min-md) {
    font-size: 16px;
  }

  @media screen and (min-width: $min-xl) {
    font-size: 18px;
  }
}

.article__title {
  font-family: $main-font;
  color: $text-black;
  //font-weight: 300;
  font-size: 22px;
  line-height: 100%;

  @media screen and (min-width: $min-md) {
    font-size: 25px;
  }


  @media screen and (min-width: $min-xl) {
    font-size: 30px;
  }
}
