@import '../../styles/_variables.scss';
@import '../../styles/_extends.scss';

.btn-go-top__box {
  position: fixed;
  bottom: 50px;
  right: calc(10px + 5%);
  cursor: pointer;

  .btn-go-top {
    position: relative;
    display: block;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    border: solid 10px rgba(255, 255, 255, 0.25);
    //@includebox-shape:;
    background-color: rgba(255, 255, 255, 0.25);
    font-family: $main-font;
    font-style: normal;
    font-weight: 200;
    width: 45px;
    height: 45px;
    font-size: 10px;
    line-height: 100%;
    color: $text-black;
    text-align: center;
    transition: .2s;
    z-index: 100;
    cursor: pointer;

    &:after {
      position: absolute;
      z-index: 101;
      content: '';
      display: block;
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 10px solid $main-blue;
      transform: translate(-50%, -60%);
      -webkit-transform: translate(-50%, -60%);
      -moz-transform: translate(-50%, -60%);
      -ms-transform: translate(-50%, -60%);
      -o-transform: translate(-50%, -60%);
    }

    @media screen and (min-width: $min-md) {}

    @media screen and (min-width: $min-lg) {
      width: 55px;
      height: 55px;
      font-size: 12px;
      line-height: 100%;
    }

    @media screen and (min-width: $min-xl) {
      width: 60px;
      height: 60px;
      font-size: 16px;
      line-height: 100%;
    }

    &:hover {
      border: solid 10px black;
      background-color: $bg-white;
      color: $text-black;
    }
  }
}

.btn-go-top__on {display: block;}

.btn-go-top__off {display: none;}