@import '../../styles/_variables.scss'; // assuming a styles directory under src/
@import '../../styles/_extends.scss'; // assuming a styles directory under src/
//@import '~nprogress/nprogress'; // importing a css file from the nprogress node module

#creators {
    user-select: text;
	//padding: 5vh 0 0 0;
	background-color: $main-blue;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: $bg-white;
	max-width: 1200px;
	margin: 0 auto;

	.article {
		height: auto;
		min-height: auto;

		.swiper-pagination {
			max-width: 100vw;
		}

		.swiper-pagination-bullets {
			bottom: calc(60px);

			@media screen and (min-width: $min-lg) {
				bottom: calc(10vh + 10px);
			}
		}
	}

	.article--text {
		position: relative;
		@include paddingRight;
		@include paddingLeft;

		.pattern__box {
			z-index: 0;
		}

		.section__header {
			color: $main-green;
		}

		.section__header, .section__title {
			text-align: center;
		}

		.section__title,
		.description__text {
			color: $text-white;
		}

		.description__text {
		//	@include paddingRight;
		//	@include paddingLeft;
			padding-top: 0;
			text-align: left;
			line-height: 1.5;
			font-size: 18px;

		}
	}

	.article--img {
		position: relative;
		padding: 0;
		text-align: center;
		width: 100%;
		//height: 550px;
		//min-height: 550px;
		padding-bottom: 50px;
		
		
		@media screen and (min-width: $min-lg) {
			padding: 0 2.5%;
			padding-bottom: 10vh;
			width: auto;
			height: auto;
		}
	}
}