@import "../../styles/_variables.scss"; // assuming a styles directory under src/
//@import '~nprogress/nprogress'; // importing a css file from the nprogress node module
@import "../../styles/_extends.scss";

.App-footer {
  user-select: none;
  position: relative;
  background-color: $main-grey;
  @include section-padding;
  @include section-margin;
  margin-bottom: 0;
  background-image: url("../../images/icons/Ellipse\ 7.svg"), url("../../images/icons/Ellipse\ 8.svg");
  background-repeat: no-repeat;
  background-size: cover, 50%;
  background-position: left 250px top -70px, left 620px top 230px;

  .footer__title {
    text-align: left;
    @include flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;

    .pattern__box {
      z-index: 0;

      @media screen and (min-width: $min-md) {
        margin-top: 0;

        .points__pattern {
          text-align: right;
          margin-right: 0;
        }
      }
    }
  }

  .footer__info {
    @include flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @include section-margin;

    @media screen and (min-width: $min-md) {
      align-items: flex-start;
      margin-bottom: 0;
    }

    .article {
      flex: 1;
      min-height: auto;
    }

    .article:not(:first-child) {
      padding-left: 20px;
    }

    .article--text {
      @include flex;
      margin-bottom: 5vh;

      .persons__list {
        padding-left: 0;

        .list__elem {
          color: $text-black;
          text-align: center;
          list-style: none;
          padding-bottom: 0.5rem;

          @media screen and (min-width: $min-lg) {
            text-align: left;
          }

          a {
            color: $text-black;
            text-decoration: none;
            user-select: none;
            transition: .2s;
          }

          &:hover {

            a {
              color: $main-blue;
            }
          }
        }
      }
    }
  }

  .footer__authors {
    text-align: center;
    @include section-margin;
    margin-bottom: 0;

    .row {
      @include flex;

      .App-logo {
        height: 30px;
        background: $main-grey;
        margin-bottom: 6vh;
      }
    }

    .description__text {
      color: $text-black;
    }

    div,
    a {
      color: $text-black;
      font-family: $main-font;
    }
  }
}