@import '../../styles/_variables.scss'; // assuming a styles directory under src/
@import '../../styles/_extends.scss'; // assuming a styles directory under src/
//@import '~nprogress/nprogress'; // importing a css file from the nprogress node module

#dialog {
  @include paddingTop;
  margin-top: 50px;
  color: $main-blue;
  overflow: hidden;
  user-select: text;

  .dialog__row {
    position: relative;
    @include flex;
    @include section-padding;
    background-color: $main-green;
    // margin-bottom: 100px;

    .section__title {
      color: $main-blue;
      text-align: center;
    }

    .section__text {
      color: $main-blue;
    }

    .article__icon {
      background-color: $bg-white;
      height: 280px;
      width: 280px;
      margin: -50px auto 0;
      border-radius: 50%;

      .icon--large {
        height: 90%;
        user-select: none;
      }
    }

    .article__questions {
      @include section-padding;
      z-index: 1;

      .question__list {
        padding-left: 20px;

        .list__item {
          margin-bottom: 20px;
        }
      }
    }

    .ellipse__box {
      position: absolute;
      height: 120%;
      top: -115%;
      left: 50%;
      z-index: 0;

      .ellipse__pattern {
        text-align: center;
        height: 100%;
        transform: translateX(-50%);
      }
    }

    .ellipse__box--inner {
      display: none;
    }
  }

  @media screen and (min-width: $min-md) {

    .dialog__row {
      align-items: center;
      justify-content: space-between;

      .section__title {
        color: $main-blue;
        text-align: left;
      }

      .article__icon {
        margin: 0;
        margin-right: 5%;
        background-color: $bg-white;
        height: 300px;
        width: 300px;

        @media screen and (min-width: $min-lg) {
          height: 350px;
          width: 350px;
        }

        @media screen and (min-width: $min-xl) {
          height: 400px;
          width: 400px;
        }

        .icon--large {
          height: 90%;
        }
      }

      .article__questions {
        padding: 0;
        margin-right: 5%;
        width: auto;
      }

      //@include paddingsAll;
      .ellipse__box {
        position: absolute;
        height: 120%;
        top: 0%;
        left: 100%;
        z-index: 0;

        .ellipse__pattern {
          text-align: center;
          height: 100%;
          transform: translateX(-50%);
        }
      }

      .ellipse__box--inner {
        display: block;

        .ellipse__pattern {
          text-align: center;
          height: 50%;
          transform: translate(-50%, 50%);
        }
      }

      &:first-of-type {
        padding-bottom: 0;
      }

      &:last-of-type {
        padding-top: 0;

        .article__questions {
          margin: 0 5%;
          width: 60%;
        }
      }
    }
  }

}