@import '../../styles/_variables.scss';
@import '../../styles/_extends.scss';

#prototype {
  position: relative;
  @include flex-column;
  @include section-padding;
  padding-top: 0;
  margin-top: 10rem;
  padding-left: 0;
  padding-right: 0;

  .article {
    &:first-of-type {

      .section__title {
        color: $text-black;
        text-align: center;
      }

      .section__header {
        color: $main-blue;
        margin-top: 50px;
        text-align: center;

      }

      @media screen and (min-width: $min-md) {


        .section__header {}
      }
    }

    &:last-of-type {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(4, 1fr);

      .flex-row {
        @include flex;
        background: $main-blue;

        @media screen and (min-width: $min-md) {
          &:nth-child(even) {
            flex-direction: row-reverse;
          }
        }

        &:nth-child(odd) {
          text-align: right;

          &:after {
            right: 0;
          }
        }

        &:nth-child(even) {
          text-align: left;
        }
      }

      .text__box {
        width: 100%;
        height: auto;
        height: 100%;
        background-color: $main-blue;
        //padding: 20px;
        display: grid;
        align-content: end;

        .article__title,
        .section__text {
          color: $text-white;
          margin: 10% 10%;
        }

        .article__title {
          font-size: 40px;

        }

        .section__text {
          position: relative;

          &:after {
            display: block;
            position: absolute;
            content: '';
            width: 8%;
            border: solid 2px $main-green;
            top: -15%;

          }
        }



        // &:nth-of-type(1) {
        //   align-content: space-between;

        //   .article__title,
        //   .section__text {
        //     text-align: right;
        //   }
        // }

        // &:nth-of-type(odd) {
        //   justify-content: start;

        //   .article__title,
        //   .section__text {
        //     text-align: right;

        //     &:after {
        //       top: -25px;
        //       right: 0;
        //     }
        //   }
        // }

        // &:nth-of-type(even) {
        //   justify-content: end;

        //   .article__title,
        //   .section__text {
        //     text-align: left;

        //     &:after {
        //       top: -25px;
        //       left: 0;
        //     }
        //   }
        // }
      }

      .flex-row {
        &:nth-child(odd) {
          .section__text {

            &:after {
              right: 0;
            }
          }
        }

      }

      .img__box {
        width: 100%;
        height: auto;
        //padding: 20px;
        background-color: $main-grey;
        display: grid;
        align-content: center;
        justify-content: center;

        .section__photo {
          width: 90%;
          margin: 20px auto;
        }
      }
    }
  }

  .ellipse__box {
    // display: none;
    position: absolute;
    display: block;
    height: 15%;
    bottom: -8%;
    z-index: -1;
    left: -55%;

    .ellipse__pattern {
      text-align: center;
      height: 100%;
      //z-index: 0;
      //transform: translateY(-50%);
    }
  }

  .ellipse__box--inner {
    .ellipse__pattern {
      height: 50%;
      transform: translate(50%, 50%);
      -webkit-transform: translate(50%, 50%);
      -moz-transform: translate(50%, 50%);
      -ms-transform: translate(50%, 50%);
      -o-transform: translate(50%, 50%);
    }
  }

  @media screen and (min-width: $min-md) {
    .ellipse__box {
      height: 60%;
      bottom: -12%;
      left: -55%;
      z-index: -2;

      .ellipse__pattern {
        text-align: center;
        height: 100%;
        //z-index: 0;
        //transform: translateY(-50%);
      }
    }

    .ellipse__box--inner {
      .ellipse__pattern {
        height: 50%;
        transform: translate(50%, 50%);
        -webkit-transform: translate(50%, 50%);
        -moz-transform: translate(50%, 50%);
        -ms-transform: translate(50%, 50%);
        -o-transform: translate(50%, 50%);
      }
    }
  }

  @media screen and (min-width: $min-xl) {
    .ellipse__box {
      height: 60%;
      bottom: -15%;
      left: -50%;
    }
  }
  img{
    user-select: none;
  }
}