@import 'styles/_variables.scss'; // assuming a styles directory under src/
//@import '~nprogress/nprogress'; // importing a css file from the nprogress node module

html {
  scroll-behavior: smooth;
}

.App {
  overflow: hidden;
}
