@import '../../styles/_variables.scss'; // assuming a styles directory under src/
//@import '~nprogress/nprogress'; // importing a css file from the nprogress node module

.page__title {
  font-family: $main-font;
  color: $text-black;
  font-weight: 300;
  font-size: 27px;
  line-height: 100%;
  margin-bottom: 30px;

  @media screen and (min-width: $min-md) {
    font-size: 30px;
  }

  @media screen and (min-width: $min-lg) {
    font-size: 40px;
  }

  @media screen and (min-width: $min-xl) {
    font-size: 50px;
  }

  @media screen and (min-width: $min-xxl) {
    font-size: 70px;
  }
}

.page__subtitle {
  font-family: $main-font;
  color: $text-black;
  font-weight: 300;
  font-size: 15px;
  line-height: 100%;
  margin-top: 0;
  margin-bottom: 20px;

  @media screen and (min-width: $min-md) {
    font-size: 15px;
    text-align: left;
  }

  @media screen and (min-width: $min-lg) {
    font-size: 20px;
  margin-bottom: 30px;

  }

  @media screen and (min-width: $min-xl) {
    font-size: 25px;
  margin-bottom: 35px;

  }

  @media screen and (min-width: $min-xxl) {
    font-size: 35px;
  margin-bottom: 40px;

  }
}
