@import '../../styles/_variables.scss'; // assuming a styles directory under src/
//@import '~nprogress/nprogress'; // importing a css file from the nprogress node module

.btn__primary {
  background-color: transparent;
  border: 2px solid $main-blue;
  border-radius: $brdr-radius;
  font-family: $main-font;
  font-size: 16px;
  line-height: 48px;
  padding: 0px 14px;
  color: $main-blue;
  cursor: pointer;
  transition: $transition;

  &:hover {
    background-color: $main-blue;
    color: $main-green;
  }

  @media screen and (min-width: $min-md) {
    font-size: 18px;
    line-height: 52px;
    padding: 0px 16px;
    border: $brdr-weight solid $main-blue;
  }

  @media screen and (min-width: $min-lg) {
    font-size: 19px;
    line-height: 54px;
    padding: 0px 17px;
  }

  @media screen and (min-width: $min-xl) {
    font-size: 20px;
    line-height: 56px;
    padding: 0px 18px;
    border: $brdr-weight solid $main-blue;
  }

  @media screen and (min-width: $min-xxl) {
    font-size: 22px;
    line-height: 60px;
    padding: 0px 20px;
  }
}
