@mixin flex {
  display: flex;
  flex-direction: column;
  //justify-content: center;
  //align-items: center;

  @media screen and (min-width: $min-md) {
    flex-direction: row;
  }
}

@mixin bg-gradient {
  background-image: linear-gradient(to top, $main-blue, rgba(71, 0, 201, 0.1));
  background-color: rgba(30, 144, 255, 1);
}

@mixin bg-gradient--reverse {
  background-image: linear-gradient(to bottom, $main-blue, rgba(71, 0, 201, 0.1));
  background-color: rgba(30, 144, 255, 1);
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin text-center {
  .section__title, .description__text {
    text-align: center;
  }
}

@mixin icon($url) {
  background-image: url($url);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  
}

@mixin photo($url) {
  background-image: url($url);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  background-size: cover;
}

// @mixin box-shape {
//   box-shadow: $box-shadow;
//   border-radius: $box-radius;
// }

@mixin paddingLeft {
  padding-left: calc(20px + 5vw);

  @media screen and (min-width: $min-md) {
    padding-left: 80px;
  }

  @media screen and (min-width: $min-lg) {
    padding-left: calc(80px + 2vw);
  }

  @media screen and (min-width: $min-xl) {
    padding-left: calc(100px + 2vw);
  }

  @media screen and (min-width: $min-xxl) {
    padding-left: 150px;
  }
}

@mixin paddingRight {
  padding-top: calc(20px + 5vw);

  @media screen and (min-width: $min-md) {
    padding-right: 80px;
  }

  @media screen and (min-width: $min-lg) {
    padding-right: calc(80px + 2vw);
  }

  @media screen and (min-width: $min-xl) {
    padding-right: calc(100px + 2vw);
  }

  @media screen and (min-width: $min-xxl) {
    padding-right: 150px;
  }
}

@mixin paddingLeft {
  padding: calc(20px + 5vw);

  @media screen and (min-width: $min-md) {
    padding-left: 80px;
  }

  @media screen and (min-width: $min-lg) {
    padding-left: calc(80px + 2vw);
  }

  @media screen and (min-width: $min-xl) {
    padding-left: calc(100px + 2vw);
  }

  @media screen and (min-width: $min-xxl) {
    padding-left: 150px;
  }
}

@mixin paddingTop {
  padding-top: calc(20px + 5vh);

  @media screen and (min-width: $min-md) {
    padding-top: calc(50px + 5vh);
  }

  @media screen and (min-width: $min-lg) {
    padding-top: calc(50px + 5vh);
  }

  @media screen and (min-width: $min-xl) {
    padding-top: calc(50px + 5vw);
  }

  @media screen and (min-width: $min-xxl) {
    padding-top: calc(50px + 5vw);
  }
}

@mixin paddingBottom {
  padding-bottom: calc(20px + 5vh);

  @media screen and (min-width: $min-md) {
    padding-bottom: calc(50px + 5vh);
  }

  @media screen and (min-width: $min-lg) {
    padding-bottom: calc(50px + 5vh);
  }

  @media screen and (min-width: $min-xl) {
    padding-bottom: calc(50px + 5vw);
  }

  @media screen and (min-width: $min-xxl) {
    padding-bottom: calc(50px + 5vw);
  }
}

@mixin  paddingsAll {
  @include paddingLeft;
  @include paddingRight;
  @include paddingTop;
  @include paddingBottom;  
}

