@import "../../styles/_variables.scss"; // assuming a styles directory under src/
@import "../../styles/_extends.scss"; // assuming a styles directory under src/

.navbar {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 40px);
  max-width: 100vw;
  z-index: 99;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 20px;

  @media screen and (min-width: $min-md) {
    align-items: flex-end;
    @include paddingRight;
    @include paddingLeft;
    padding-top: 5vw;
    width: calc(100% - 160px);
    height: 40px;
    max-width: 1200px;
    margin: 0;
  }

  @media screen and (min-width: $min-lg) {
    width: calc(100% - (80px + 2vw) * 2);
  }

  @media screen and (min-width: $min-xl) {
    width: calc(100% - (100px + 2vw) * 2);
  }

  @media screen and (min-width: $min-xxl) {
    height: 40px;
    width: calc(100% - 300px);
  }

  .brand-logo {
    height: 30px;
    user-select: none;

    @media screen and (min-width: $min-md) {
      height: 40px;
      opacity: 0.1;
    }

    @media screen and (min-width: $min-xxl) {
      height: 45px;
    }

    @media screen and (max-width: $max-sm) {
      opacity: 0;
    }
  }

  .hamburger-btn {
    background-color: transparent;
    width: min-content;
    height: min-content;
    border: none;
    cursor: pointer;
    z-index: 99;
    user-select: none;
    padding-top: 0.5rem;

    @media screen and (min-width: $min-md) {
      display: none;
    }

    .hamburger-icon {
      height: 30px;
    }
  }

  .right-bar {
    display: flex;
    align-items: center;

    .lang-check {
      display: flex;
      align-items: center;

      .lang-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        margin-bottom: 5px;
        background: none;
        width: 30px;
        height: 20px;
        padding: 0;
        //border: solid 2px white;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
        cursor: pointer;
        transition: $transition;
        background-color: gray;
        opacity: 1;

        &:hover {
          transform: scale(1.1);
        }

        &.pl,
        &.en {
          opacity: 0.5;

          &:hover {
            transform: scale(1);
          }
        }

        .flags {
          border-radius: 3px;
          width: 35px;
          height: 20px;
        }
      }
    }
  }

  .nav-links {
    width: 60%;
    align-items: end;
    justify-content: space-between;
    display: none;

    @media screen and (min-width: $min-md) {
      display: flex;
    }

    .link {
      cursor: pointer;
      text-decoration: none;
      color: $text-black;
      border-bottom: solid 2px transparent;
      transition: $transition;
      font-size: 14px;
      user-select: none;

      @media screen and (min-width: $min-md) {
        font-size: 16px;
      }

      @media screen and (min-width: $min-lg) {
        font-size: 17px;
      }

      @media screen and (min-width: $min-xl) {
        font-size: 18px;
      }

      @media screen and (min-width: $min-xxl) {
        font-size: 20px;
      }

      &:hover {
        border-bottom: solid 2px $main-blue;
      }
    }

    .link--active {
      border-bottom: solid 2px $main-blue;
      user-select: none;
    }
  }

  .nav-links--off {
    display: none;
  }

  .nav-links--on {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: -10px;
    left: -20px;
    height: 100vh;
    width: 100vw;
    background-color: $main-blue;
    animation: openNav 0.1s;

    .link {
      color: $text-white;
      margin: 5%;
      height: 30px;

      &:hover {
        border-bottom: solid 2px $main-green;
      }
    }

    @keyframes openNav {
      from {
        height: 0;
      }

      to {
        height: 100vh;
      }
    }
  }
}

#no-opacity {
  opacity: 1;
}