@import '../../styles/_variables.scss';
@import '../../styles/_extends.scss';

.photo__gallery {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(7, 200px);
  grid-gap: 20px;

  @include paddingLeft;
  @include paddingRight;
  @include paddingBottom;
  padding-top: 20px;
  z-index: 0;
  position: relative;

  &:after {
    display: block;
    position: absolute;
    content: '';
    background-color: $main-blue;
    width: 45%;
    height: 500px;
    z-index: -1;
    left: 0;
    bottom: 0;
  }

  .grid__item {
    .grid__photo {
      height: 100%;
    }
  }


  @media screen and (min-width: $min-md) {
    height: 100vh;
    min-height: 700px;
    display: grid;
    grid-template-columns: repeat(3, 33.3%);
    grid-template-rows: repeat(6, 16.6%);
    grid-gap: 20px;
    max-width: 100%;

    &:after {
      width: 50%;
      height: 45%;
      bottom: -50px;
    }

    .grid__item {
      max-width: auto;
      margin: 0;
      overflow: hidden;

      .grid__photo {
        height: auto;
        width: 100%;
      }

      &:nth-of-type(1) {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 3;
      }

      &:nth-of-type(2) {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 3;
        grid-row-end: 3;
      }

      &:nth-of-type(3) {
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start: 3;
        grid-row-end: 3;
      }

      &:nth-of-type(4) {
        grid-column-start: 3;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 4;

        .grid__photo {
          height: 100%;
          width: auto;
        }
      }

      &:nth-of-type(5) {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 4;
        grid-row-end: 4;
      }

      &:nth-of-type(6) {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 5;
        grid-row-end: 7;

        .grid__photo {
          height: 90%;
          width: auto;
        }
      }

      &:nth-of-type(7) {
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row-start: 4;
        grid-row-end: 6;
      }
    }
  }
}