@import '../../styles/_variables.scss';
@import '../../styles/_extends.scss';

.swiper-container {
  position: static;
}

.swiper-button-next,
.swiper-button-prev {
  visibility: hidden;

  @media screen and (min-width: $min-lg) {
    visibility: visible;
  }
}

.swiper-button-next {
  @media screen and (min-width: $min-lg) {
    position: absolute;
    right: 20px;
  }
}

.swiper-button-prev {
  @media screen and (min-width: $min-lg) {
    position: absolute;
    left: 20px;
  }
}

.swiper-pagination {
  max-width: 80vw;
  width: calc(90vw - 40px);

  @media screen and (min-width: $min-lg) {
    max-width: 100%;
    width: 100%;
  }

  @media screen and (min-width: $min-xl) {
    width: 100%;

  }

  @media screen and (min-width: $min-xxl) {
    
  }
}

.swiper-container-horizontal {

  .swiper-pagination-bullets {
    bottom: 0px;

    @media screen and (min-width: $min-lg) {
      width: 100%;
      left: 0;
    }

    @media screen and (min-width: $min-xl) {
      width: 100%;
    }

    @media screen and (min-width: $min-xxl) {
      
    }
  }
}