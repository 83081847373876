@import '../../styles/_variables.scss'; // assuming a styles directory under src/
@import '../../styles/_extends.scss'; // assuming a styles directory under src/
//@import '~nprogress/nprogress'; // importing a css file from the nprogress node module

#stats {
  position: relative;
  background-color: transparent;
  @include section-padding;
  @include section-margin;
  margin-bottom: 30px;
  //@include flex;
  //justify-content: center;
  user-select: text;

  @media screen and (min-width: $min-md) {
    @include section-margin;
  }

  .article {
    width: 100%;

    .section__title {
      text-align: center;
    }
  }

  .stats-charts__box {
    @include flex;
    justify-content: space-around;
    margin: 50px auto;

    .stat__box {
      min-width: 150px;
      min-height: 150px;
      //background-color: $main-grey;
      // border-radius: 50%;
      // -webkit-border-radius: 50%;
      // -moz-border-radius: 50%;
      // -ms-border-radius: 50%;
      // -o-border-radius: 50%;
      margin: 20px;
      
      @media screen and (min-width: $min-md) {
        margin: 10px;
      }
    }

    .stat__box {
      flex: 1;
    
      .stat__chart {
        position: relative;
        display: flex;
        justify-content: center;
    
        .number {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 50px;
          color: $main-blue;
          font-weight: 300;
          margin: 0;
          z-index: 1;
          line-height: 100%;

          .procentage {
            font-size: 20px;
          }
        }

        div {
          z-index: 0;
        }
      }
    }

    .stat__description {
      .info__text {
        line-height: 140%;
      }
    }
  }

  .stats-text__box {
    @include flex;
    justify-content: space-around;

    .section__text {
      flex: 10;
      
      @media screen and (min-width: $min-md) {
        text-align: left;
        max-width: 40%;
      }
    }

    .border-line__box {
      flex: 1;
      @include flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      .border-line {
        border-bottom: dotted 2px $main-blue;
        height: 0px;
        width: 50%;
      }

      @media screen and (min-width: $min-md) {
        max-width: 10px;
        
        .border-line {
          height: 70%;
          width: 0px;
          border-left: dotted 4px $main-blue;
        }
      }
    }
  }
}