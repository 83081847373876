@import '../../styles/_variables.scss'; // assuming a styles directory under src/
@import '../../styles/_extends.scss'; // assuming a styles directory under src/

.App-header {
  position: relative;
  //@include paddingsAll;
  @include paddingRight;
  @include paddingLeft;
  @include paddingTop;
  padding-bottom: 0;
  @include flex;
  //flex-direction: row;
  flex-wrap: wrap;
  background-color: transparent;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: calc(10px + 2vmin);
  color: $bg-white;
  max-width: 1200px;
  margin: 0 auto;
  //overflow: hidden;

  .App-circle {
    position: absolute;
    width: 300%;
    bottom: -5%;
    right: 50%;
    transform: translateX(50%);
    user-select: none;

    @media screen and (min-width: $min-md) {
      width: 30%;
      top: -30%;
      right: -20%;
      transform: translateX(0);
    }

    @media screen and (min-width: $min-lg) {
      width: 50%;
      top: -35%;
      right: -20%;
    }

    @media screen and (min-width: $min-xl) {
      width: 70%;
      top: -35%;
      right: -20%;
    }

    @media screen and (min-width: $min-md) {
      width: 80%;
      top: -35%;
      right: -20%;
    }
  }

  .article {
    position: relative;

    .App-logo {
      height: 60px;
      pointer-events: none;
      background-color: transparent;
      margin: 20px 0%;
      user-select: none;

      @media screen and (min-width: $min-md) {
        height: 65px;
        margin: 0%;
      }

      @media screen and (min-width: $min-lg) {
        height: 70px;
      }

      @media screen and (min-width: $min-xl) {
        height: 75px;
      }

      @media screen and (min-width: $min-xxl) {
        height: 80px;
      }
    }

    .App-picture {
      height: 200px;
      user-select: none;

      @media screen and (min-width: $min-md) {
        height: 250px;
      }

      @media screen and (min-width: $min-lg) {
        height: 300px;
      }

      @media screen and (min-width: $min-xl) {
        height: 400px;
      }

      @media screen and (min-width: $min-xxl) {
        height: 500px;
      }

      &:nth-of-type(1) {
        height: 160px;
        margin-right: 20%;
        transform: translateX(-50%);

        @media screen and (min-width: $min-md) {
          height: 220px;
          transform: translateX(0);
        }

        @media screen and (min-width: $min-lg) {
          height: 280px;
        }

        @media screen and (min-width: $min-xl) {
          height: 340px;
        }

        @media screen and (min-width: $min-xxl) {
          height: 440px;
        }
      }

      &:nth-of-type(2) {
        top: 50%;
        left: 25%;
        position: absolute;
        transform: translateY(-50%);

        @media screen and (min-width: $min-md) {
          left: 50%;
        }
      }
    }

    .App-link {
      margin: 20px 0%;
      user-select: none;

      @media screen and (min-width: $min-md) {
        margin: 0%;
      }
    }

    &:first-of-type {
      display: flex;
      flex-direction: column;
      margin-bottom: 15%;

      @media screen and (min-width: $min-md) {
        width: 60%;
        height: auto;
        align-items: flex-start;
        margin-bottom: 0%;
        margin-top: 2%;

        .page__title {
          text-align: left;
          user-select: text;
        }
      }
    }

    &:last-of-type {
      margin-bottom: 15%;

      @media screen and (min-width: $min-md) {
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0%;
      }
    }
  }

  .pattern__box {

    &:first-of-type {
      display: block;

      @media screen and (min-width: $min-md) {
        display: none;
      }
    }

    &:last-of-type {
      display: block;

      @media screen and (min-width: $min-md) {
        display: flex;
      }
    }
  }
}

.App-header-2nd {
  @extend .App-header;
  min-height: 100px;
}

img{
user-select: none;
}

