@import '../../styles/_variables.scss'; // assuming a styles directory under src/
@import '../../styles/_extends.scss'; // assuming a styles directory under src/

#how-section {
	position: relative;
	//background-color: $bg-white;
	@include paddingsAll;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: auto;
	text-align: left;
	margin-bottom: 60px;

	.how__title {
		@include flex;
		flex-direction: column-reverse;
		justify-content: space-between;
		align-items: flex-start;
		text-align: left;
		width: 100%;

		.article__title {
			font-size: 2rem;
			color: $main-blue;
		}

		.pattern__box {
			z-index: 0;
			margin: 0;
			justify-content: flex-end;
		}

		@media screen and (min-width: $min-md) {
			flex-direction: row-reverse;
			justify-content: space-between;
			align-items: flex-start;
			//padding-right: 60px;
			padding-left: 60px;
			width: 95%;

			.article__title {
				font-size: 3rem;
				width: 100%;
				margin-top: 0;
			}

			.pattern__box {
				justify-content: end;
				align-items: flex-start;
				margin: 0;
			}
		}
	}

	.container--rows {
		//display: flex;
		flex-direction: column;
		height: 85vh;
		min-height: 550px;
	}

	.ellipse__box {
		// display: none;
		position: absolute;
		display: block;
		height: 50%;
		bottom: -4%;
		z-index: -1;
		left: 50%;

		.ellipse__pattern {
			text-align: center;
			height: 100%;
			//z-index: 0;
			//transform: translateY(-50%);
		}
	}

	.ellipse__box--inner {
		.ellipse__pattern {
			height: 50%;
			transform: translate(50%, 50%);
			-webkit-transform: translate(50%, 50%);
			-moz-transform: translate(50%, 50%);
			-ms-transform: translate(50%, 50%);
			-o-transform: translate(50%, 50%);
		}
	}

	@media screen and (min-width: $min-md) {
		position: relative;
		.container--rows {
			height: 60vh;
		}

		.ellipse__box {
			height: 60%;
			bottom: -10%;
			left: 60%;
			z-index: -2;

			.ellipse__pattern {
				text-align: center;
				height: 100%;
				//z-index: 0;
				//transform: translateY(-50%);
			}
		}

		.ellipse__box--inner {
			.ellipse__pattern {
				height: 50%;
				transform: translate(50%, 50%);
				-webkit-transform: translate(50%, 50%);
				-moz-transform: translate(50%, 50%);
				-ms-transform: translate(50%, 50%);
				-o-transform: translate(50%, 50%);
			}
		}
	}

	@media screen and (min-width: $min-lg) {
		.ellipse__box {
			height: 70%;
			bottom: -12%;
			left: 70%;
		}
	}

	@media screen and (min-width: $min-xl) {
		.ellipse__box {
			height: 80%;
			bottom: -20%;
			left: 80%;
		}
	}
	img{
	user-select: none;
	}
}