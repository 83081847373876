@import '../../styles/_variables.scss'; // assuming a styles directory under src/
@import '../../styles/_extends.scss'; // assuming a styles directory under src/
//@import '~nprogress/nprogress'; // importing a css file from the nprogress node module

#questionnaire {
    user-select: text;
	//padding: 5vh 0 0 0;
	@include section-padding;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: $main-blue;
	max-width: 1200px;
	margin-bottom: 60px;

	.article {
		@include section-padding;
		padding-top: 60px;
		background-color: $main-green;
		position: relative;

		.pattern__box {
			z-index: 0;
		}

		.section__header {
			color: $main-blue;
		}

		.section__header,
		.section__title {
			text-align: center;
		}

		.section__title,
		.description__text {
			color: $main-blue;
		}

		.description__text {
			//	@include paddingRight;
			//	@include paddingLeft;
			padding-top: 0;
			text-align: left;
			line-height: 1.5;
			font-size: 18px;
		}
	}

	.article--btn {
		padding-top: 30px;
		padding-bottom: 90px;
		display: flex;
		justify-content: center;

		.App-link {
			margin: 0 auto;

			.btn__primary {
				background-color: $main-blue;
				//color: $main-green;
				color: $bg-white;
			}

			&:hover {
				.btn__primary {
					background-color: transparent;
					//color: $main-green;
					color: $main-blue;
				}
			}
		}
	}

	@media screen and (min-width: $min-md) {
		@include paddingsAll;

		.article {
			@include section-padding;
			//padding-bottom: 40px;

			.section__header,
			.section__title {
				text-align: left;
			}

			.description__text {
				margin-bottom: 0;
			}
		}

		.article--btn {
			padding-top: 0px;
			padding-bottom: 90px;
		}
	}
}