@import "../../styles/_variables.scss"; // assuming a styles directory under src/
//@import '~nprogress/nprogress'; // importing a css file from the nprogress node module
@import "../../styles/_extends.scss";

#works {
  user-select: text;
  min-height: auto;
  margin-bottom: 0;
  //@include bg-gradient;
  background-color: $main-grey;
  @include paddingsAll;
  margin-bottom: 60px;
  position: relative;
  z-index: -2;

  &::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 450px;
    background-color: $bg-blueGrey;
    z-index: -1;
    left: 0;
    top: 0;
  }

  .section__header {
    color: $main-blue;
    text-align: left;
    padding-left: 30px;
  }

  @media screen and (min-width: $min-md) {
    .section__header {
      padding-left: 60px;
    }
  }

  @media screen and (min-width: $min-lg) {}

  @media screen and (min-width: $min-xl) {}

  @media screen and (min-width: $min-xxl) {}


  .article {
    height: auto;
    min-height: auto;
    padding-bottom: 60px;
    @include flex-column;
    padding-top: 0;
    color: $text-black;
    background: $bg-white;
    line-height: 1.5;

    .works__title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      text-align: left;
      padding-left: 30px;
      width: calc(100% - 30px);

      .section__title {
        font-size: 2rem;
      }

      @media screen and (min-width: $min-md) {
        padding-left: 60px;
        width: calc(100% - 60px);

        .section__title {
          font-size: 3rem;
        }
      }

      .pattern__box {
        z-index: 0;
        width: 20%;
        margin-bottom: calc(20px + 5vh);

        @media screen and (min-width: $min-md) {
          margin-bottom: calc(50px + 5vh);
          width: 15%;
        }

        @media screen and (min-width: $min-lg) {
          margin-bottom: calc(50px + 5vh);
        }

        @media screen and (min-width: $min-xl) {
          margin-bottom: calc(40px + 5vw);
        }

        @media screen and (min-width: $min-xxl) {
          margin-bottom: calc(20px + 5vw);
        }

        .points__pattern {
          margin-top: -50%;

          @media screen and (min-width: $min-md) {
            margin-top: -50%;
          }
        }
      }
    }

    .row {
      @include flex;
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 0;

      @media screen and (min-width: $min-lg) {
        padding-top: 0;
        flex: 1;
        display: grid;
        grid-template-columns: 0.5fr 2fr 1.2fr;
        @include section-padding;
        padding-bottom: 0;
      }
    }

    @media screen and (min-width: $min-lg) {
      flex: 1.1;
    }

    @media screen and (min-width: $min-xl) {
      flex: 1.2;
    }

    @media screen and (min-width: $min-xxl) {}
  }

  .box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
    padding-right: 60px;

    .dot__pattern {
      padding: 5px;
      padding-top: 0;
    }

    @media screen and (min-width: $min-md) {
      flex-direction: column;
    }
  }

  .description__text {
    text-align: left;
    line-height: 1.5;
    padding-bottom: 30px;
  }

  .img__box {
    display: flex;
    align-self: center;
    align-items: center;
    min-width: 150px;
    min-height: 150px;
    margin-bottom: 60px;
    border: 5px solid $bg-blueGrey;
    padding: 5px;
    overflow: hidden;

    .section__icon {
      display: block;

      z-index: 99;
      object-fit: cover;
      object-position: 50% 50%;
      height: 150px;
      max-width: 150px;

      @media screen and (min-width: $min-lg) {
        max-width: 100%;
        height: 100%;
      }
    }

    @media screen and (min-width: $min-md) {
      margin-left: 60px;
      margin-bottom: 60px;
    }

    @media screen and (min-width: $min-lg) {
      transform: translateY(0%);
      height: 220px;
      width: 220px;
    }
  }
}