@import '../../styles/_variables.scss'; // assuming a styles directory under src/
@import '../../styles/_extends.scss'; // assuming a styles directory under src/

.article--row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0px;
  width: calc(90vw - 40px);
  min-height: 550px;

  .article--text {
    width: 100%;
    line-height: 1.5;
    padding-right: 60px;
    padding-left: 60px;
  }

  @media screen and (min-width: $min-md) {
    width: calc(100vw - 160px);
  }

  @media screen and (min-width: $min-lg) {
    width: calc(90vw - 100px);

    padding-bottom: 60px;

    .article--text {
      width: 60%;
    }
  }

  @media screen and (min-width: $min-xl) {
    width: calc(1000px);
  }

  @media screen and (min-width: $min-xxl) {
    width: calc(900px);
  }

  .img__box {
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    min-width: 150px;
    min-height: 150px;
    height: 200px;
    width: 200px;
    background-color: $main-green;
    border-radius: 50%;
    z-index: 1;
    margin-bottom: 30px;

    .section__icon {
      width: 90%;
      height: 90%;
    }

    @media screen and (min-width: $min-md) {
      height: 250px;
      width: 250px;
    }

    @media screen and (min-width: $min-lg) {
      height: 280px;
      width: 280px;
    }

    @media screen and (min-width: $min-xl) {
      height: 300px;
      width: 300px;
    }
  }
}