@import '../../styles/_variables.scss';
@import '../../styles/_extends.scss';



#tools {
  user-select: text;
  @include flex-column;
  @include section-padding;
  padding-top: 0;
  background-color: $main-grey;
  margin-top: 10rem;

  @media screen and (min-width: $min-md) {}

  @media screen and (min-width: $min-lg) {}

  @media screen and (min-width: $min-xl) {}

  .article {
    height: auto;
    min-height: auto;
    @include section-padding;
  }

  .article--text {
    background-color: $main-blue;
    color: $text-white;
    //width: 95%;
    margin: 0 auto;
    position: relative;
    top: -100px;
    margin-bottom: 5vh;
    //padding-left: 30px;

    .section__title,
    .description__text {
      color: $text-white;
      text-align: center;
    }

    .points__pattern {
      z-index: 0;
    }

    @media screen and (min-width: $min-md) {
      @include text-center;
      width: 80%;
    }

    @media screen and (min-width: $min-lg) {
      width: 70%;
    }
  }

  .article--boxes {
    position: relative;
    @include flex-row;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;
    top: -50px;
    padding: 0;

    @media screen and (min-width: $min-md) {
      width: 100%;
      padding: 0;
      @include paddingTop;
    }

    .box {
      margin-bottom: calc(5px + 2vh);
      //flex: 1;
      text-align: center;
      width: 50%;

      .icon__box {
        margin-bottom: $margin-text-bottom;
        margin-bottom: 1vh;
        display: flex;
        justify-content: center;
        position: relative;

        &:before {
          width: 70px;
          height: 70px;
          background-color: $bg-white;
          position: absolute;
          content: "";
          justify-content: center;
          top: -18px;
          border-radius: 50%;
          align-items: center;

          @media screen and (min-width: $min-md) {}
        }

        .box__icon {
          z-index: 99;
          height: 35px;
          width: 35px;
        }
      }

      .box__title,
      .description__text {
        margin-bottom: 1vh;
        margin-top: 3vh;
      }

      @media screen and (min-width: $min-md) {
        //margin: 10px;
        margin-bottom: calc(10px + 2vh);
        width: 30%;
      }

      @media screen and (min-width: $min-lg) {
        width: 21%;
        //max-width: 300px;
      }

      @media screen and (min-width: $min-xl) {
        width: 21%;
        margin-bottom: calc(20px + 2vh);
      }

      &:nth-of-type(1) {
        .icon__box {
          .box__icon {
            @include icon('../images/icons/python.svg');
          }
        }
      }

      &:nth-of-type(2) {
        .icon__box {
          .box__icon {
            @include icon('../images/icons/js.svg');
          }
        }
      }

      &:nth-of-type(3) {
        .icon__box {
          .box__icon {
            @include icon('../images/icons/html-5.svg');
          }
        }
      }

      &:nth-of-type(4) {
        .icon__box {
          .box__icon {
            @include icon('../images/icons/css.svg');
          }
        }
      }

      &:nth-of-type(5) {
        .icon__box {
          .box__icon {
            @include icon('../images/icons/django_gray.png');
          }
        }
      }

      &:nth-of-type(6) {
        .icon__box {
          .box__icon {
            @include icon('../images/icons/react_gray.png');
          }
        }
      }

      &:nth-of-type(7) {
        .icon__box {
          .box__icon {
            @include icon('../images/icons/postgress_gray.png');
          }
        }
      }

      &:nth-of-type(8) {
        .icon__box {
          .box__icon {
            @include icon('../images/icons/jira.png');
          }
        }
      }

      &:nth-of-type(9) {
        .icon__box {
          .box__icon {
            @include icon('../images/icons/Git-Icon-Black.png');
          }
        }
      }

      &:nth-of-type(10) {
        .icon__box {
          .box__icon {
            @include icon('../images/icons/icons8-heroku-512.png');
          }
        }
      }

      &:nth-of-type(11) {
        .icon__box {
          .box__icon {
            @include icon('../images/icons/icons8-circleci-480.png');
          }
        }
      }

      &:nth-of-type(12) {
        .icon__box {
          .box__icon {
              @include icon('../images/icons/github-logo.svg')
          }
        }
      }
    }
  }
}