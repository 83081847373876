@import '../../styles/_variables.scss';

.buttonDown__link {
  position: absolute;
  width: max-content;
  height: max-content;
  bottom: 15%;

  .buttonDown {
    margin: 0;
    padding: 0px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: none;
    outline: none;
    background-color: $bg-white;
    cursor: pointer;
    transition: .2s;
    background-image: url('../../images/icons/arrow-down-blue.svg');
    background-size: 85%;
    background-repeat: no-repeat;
    background-position: center;
    
    &:hover {
      background-color: $main-blue;
    background-image: url('../../images/icons/arrow-down-green.svg');

    }
  }
}