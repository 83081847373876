@import "../../styles/_variables.scss"; // assuming a styles directory under src/
//@import '~nprogress/nprogress'; // importing a css file from the nprogress node module
@import "../../styles/_extends.scss"; // assuming a styles directory under src/
//@import '~nprogress/nprogress'; // importing a css file from the nprogress node module

#mission {
  position: relative;
  background-color: transparent;
  @include section-padding;
  @include section-margin;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;

  .article {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &:first-of-type {
      width: 100%;

      .section__header {
        color: $main-blue;
        user-select: none;
      }
    }

    &:last-of-type {
      // width: 70%;
      background-color: $main-blue;
      align-self: flex-start;
      margin-left: -30px;
      margin-right: -30px;
      padding-top: 70px;
      padding-bottom: 60px;
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-start;
      align-items: center;
      font-size: 16px;

      .text__box {
        width: 80%;

        .section__title,
        .article__title,
        .section__text {
          text-align: left;
          color: $text-white;
        }

        .section__text {
          font-size: 16px;
        }

        .article__title {
          font-size: 30px;
          font-weight: 300;
        }

        .box__list {
          padding-left: 20px;
        }
      }

      .pattern__box {
        z-index: 1;
        width: 80%;
        margin-bottom: 30px;

        @media screen and (min-width: $min-md) {
          width: 12%;
          visibility: hidden;
          //right: -40%;
        }
      }

      .img__box {
        position: absolute;
        display: flex;
        justify-content: right;
        z-index: 0;
        top: -15px;
        right: 0;
        width: 50%;

        .section__photo {
          height: 250px;
          //width: 100%;
        }
      }

      @media screen and (min-width: $min-md) {
        width: 70%;
        flex-direction: row;
        padding: 60px;
        margin-left: -60px;
        align-items: flex-start;
        justify-content: space-between;
        //z-index: 1;

        .text__box {
          width: 80%;
          .section__text {
            font-size: 20px;
          }
        }

        .img__box {
          right: 0;
          top: 0px;
          min-width: 20vw;
          max-height: auto;
          overflow: hidden;

          .section__photo {
            //width: 100%;
            height: 550px;
            width: 350px;
            margin-left: auto;
          }
        }
        // &:after {
        //   //position: absolute;
        //   content: '';
        //   display: block;
        //   width: 25%;
        //   height: 50%;
        //   background-color: grey;
        //   z-index: 0;
        // }
      }

      @media screen and (min-width: $min-lg) {
        .img__box {

          .section__photo {
            //width: 100%;
            height: 600px;
            width: 400px;
          }
        }
      }

      @media screen and (min-width: $min-xl) {
        .img__box {
          z-index: 2;
          top: 10px;

          .section__photo {
            //width: 100%;
            height: 650px;
            width: 460px;
          }
        }
      }

      @media screen and (min-width: $min-xxl) {
        .img__box {

          top: 20px;

          .section__photo {
            //width: 100%;
            height: 650px;
            width: 470px;
          }
        }
      }
    }
  }

  .ellipse__box {
    // display: none;
    position: absolute;
    display: block;
    height: 20%;
    bottom: -10%;
    //z-index: -1;
    left: 70%;

    .ellipse__pattern {
      text-align: center;
      height: 100%;
      //z-index: 0;
      //transform: translateY(-50%);
    }
  }

  .ellipse__box--inner {
    .ellipse__pattern {
      height: 50%;
      transform: translate(50%, 50%);
      -webkit-transform: translate(50%, 50%);
      -moz-transform: translate(50%, 50%);
      -ms-transform: translate(50%, 50%);
      -o-transform: translate(50%, 50%);
    }
  }

  @media screen and (min-width: $min-md) {
    .ellipse__box {
      height: 80%;
      bottom: -10%;
      left: 50%;
      z-index: -2;

      .ellipse__pattern {
        text-align: center;
        height: 100%;
        //z-index: 0;
        //transform: translateY(-50%);
      }
    }

    .ellipse__box--inner {
      .ellipse__pattern {
        height: 50%;
        transform: translate(50%, 50%);
        -webkit-transform: translate(50%, 50%);
        -moz-transform: translate(50%, 50%);
        -ms-transform: translate(50%, 50%);
        -o-transform: translate(50%, 50%);
      }
    }
  }
  img{
  user-select: none;
  }
  .section__title{
    user-select: text;
  }
}
