/* colors */
$bg-white: #FFFFFF;
$bg-blueGrey: #E7EFFF;
$text-white: #FFFFFF;
$text-black: #000000;
$main-blue: #0041C4;
$main-green: #44FE72;
$main-grey: #EFEFEF;

/* Fonts */
$main-font: Roboto,
sans-serif;

/* Margins & Paddings */
@mixin section-padding {
  padding: 20px;

  @media screen and (min-width: $min-md) {
    padding: 60px;
  }
}

@mixin section-margin {
  margin: 60px 0;
}

$margin-text-bottom: calc(10px + 2vh);

/* Borders */
$brdr-radius: 5px;
$brdr-weight: 3px;

/*Media*/
$max-xs: 575.99px;

$min-sm: 576px;
$max-sm: 767.99px;

$min-md: 768px;

$min-lg: 960px;

$min-xl: 1200px;

$min-xxl: 1500px;

/* Actions */
$transition: .2s;