@import '../../styles/_variables.scss';
@import '../../styles/_extends.scss';

.gallery--img {
    position: relative;
    padding: 0;
    text-align: right;
    width: 100%;
    height: auto;
    padding-bottom: 0;
    display: grid;
    grid-gap: 20px;
    z-index: 99;
    position: relative;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 250px);
    grid-template-areas: "one"
        "two"
        "three"
        "four"
        "five";

    @media screen and (min-width: $min-md) {
        grid-template-columns: 0.8fr 1fr 1.6fr 0.8fr;
        grid-template-rows: repeat(9, 30px);
        grid-template-areas: "one  . . five"
            "one two four five"
            "one two four five"
            "one two four five"
            "one two four five"
            "one three four five"
            "one three four five"
            "one three four five";

        &:before {
            background-color: $bg-white;
            position: absolute;
            content: "";
            width: 48%;
            height: 80%;
            z-index: -1;
            left: 25%;
        }
    }

    .gallery__item:nth-child(1) {
        grid-area: one;

        @include photo("/images/photos/_64A2325.jpg");
    }

    .gallery__item:nth-child(2) {
        grid-area: two;

        @include photo("../images/photos/_64A3177.jpg");
    }

    .gallery__item:nth-child(3) {
        grid-area: three;

        @include photo("../images/photos/_64A3189.jpg");
    }

    .gallery__item:nth-child(4) {
        grid-area: four;

        @include photo("../images/photos/_64A3174.jpg");
    }

    .gallery__item:nth-child(5) {
        grid-area: five;
        @include photo("../images/photos/_64A2402.jpg");
        object-fit: none;
    }
}