@import '../../styles/_variables.scss';
@import '../../styles/_extends.scss';

#workshops {
  user-select: text;
  min-height: auto;
  @include flex-column;
  //@include bg-gradient;

  &:after {
    display: block;
    position: absolute;
    content: '';
    background-color: $main-grey;
    width: 68%;
    height: 580px;
    z-index: -1;
    right: 0;
    background-image: url("../../images/icons/Ellipse\ 7.svg"), url("../../images/icons/Ellipse\ 8.svg");
    background-repeat: no-repeat;
    background-size: cover, 60%;
    background-position: right -160px top 200px, right -30px top 380px;
  }

  @media screen and (min-width: $min-md) {
    &:after {
      width: 62%;
    }
  }

  @media screen and (min-width: $min-lg) {}

  @media screen and (min-width: $min-xl) {}

  @media screen and (min-width: $min-xxl) {}

  .article {
    height: auto;
    min-height: auto;

    &:first-of-type {
      display: flex;
      flex-direction: row;
      @include paddingsAll;
      //@include paddingTop;
      padding-bottom: 30px;
      align-items: center;
      font-size: 1.75rem;


      @media screen and (min-width: $min-md) {
        justify-content: center;
      }

      .section__title {
        width: 35%;

        @media screen and (min-width: $min-md) {
          margin-left: -30px;
          width: 40%;
        }

        @media screen and (min-width: $min-lg) {
          margin-left: -30px;
          width: 50%;
        }
      }

      .pattern__box {
        z-index: 0;
        width: auto;
      }
    }
  }

  .article--text {
    color: $text-white;

    @media screen and (min-width: $min-md) {
      flex: 1;
      @include text-center;
    }

    @media screen and (min-width: $min-lg) {
      flex: 1.1;
    }

    @media screen and (min-width: $min-xl) {
      flex: 1.2;
    }

    @media screen and (min-width: $min-xxl) {}
  }

  .article--img {
    @include flex;
    width: 100%;
    justify-content: flex-start;
    padding: 0;
    margin-bottom: 5vh;

    .article__img {
      margin: 10px auto;
    }

    @media screen and (min-width: $min-md) {
      flex-wrap: wrap;
      flex: 1;
      justify-content: center;
      align-items: center;

      .article__img {
        margin: 10px 10px;
        width: auto;
        max-width: 30%;
        height: auto;
        max-height: auto;
      }
    }
  }
}