@import '../../styles/_variables.scss'; 
@import '../../styles/_extends.scss';

.swiper-button-next, .swiper-button-prev {
  visibility: hidden;

  @media screen and (min-width: $min-lg) {
    visibility: visible;
  }
}

.swiper-pagination {
  max-width: 100vw;
}